
@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;700&display=swap');



/*
Fuentes:
font-family: "Titillium Web", sans-serif;
font-family: 'Roboto', sans-serif;
font-family: 'Lato', sans-serif;
font-family: 'Poppins', sans-serif;
font-family: 'Roboto Condensed', sans-serif;
font-family: 'Inter', sans-serif;
font-family: 'Quicksand', sans-serif;

*/

@font-face {
  font-family: 'ProximaNova';
  src: local('ProximaNova'), url(./fonts/proximanova-regular.otf) format('opentype');
}

/*=======
@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
*/

* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  font-family: "Titillium Web", sans-serif;

/*
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  line-height: 1.65;
*/

}

p {
  color: rgb(148 163 184/var(--tw-text-opacity))
}



#root {
  /* background-color: #1d1d1d; */
  color: WHITE;
  /*background-image: url(../images/Fondos/Hola.webp);*/
  background-color: rgb(15 23 42/var(--tw-bg-opacity));
  background-image: url(../images/Fondos/hero.jpg);
  z-index: 99999999;
}

/* unvisited link */
.newFooter a:link {
  color: rgb(148 163 184/var(--tw-text-opacity));
  text-decoration: none;
}

/* visited link */
.newFooter a:visited {
  color: rgb(148 163 184/var(--tw-text-opacity));
  text-decoration: none;
}

/* mouse over link */
.newFooter a:hover {
  color: rgb(148 163 184/var(--tw-text-opacity));
  text-decoration: none;
}

/* selected link */
.newFooter a:active {
  color: rgb(148 163 184/var(--tw-text-opacity));
  text-decoration: none;
}

:root {
  --mainColor: #29335c;
  --mainColorLight: #5767aa;
  --secondaryColor: #db2b39;
  --textColor: white;
  --lime: #c7db00;
  --cilantro: #7aa802;
  --lox: #f78b2d;
  --toas: #e4b600;
  --black: #000000;
  --negro-pastel: rgb(20, 20, 20);
  --blue-pastel: #4e89e5;
  --green-pastel-btn: #77dd77;
  --red-pastel-btn: #ff6961;
  --gray-pastel: #cdcdcd;
  --naranja-ligero: #fab273;
  --naranja-oscuro: #e16d09;
  --cilantro-saturado: #83bd00;
  --cilantro-oscuro: #628602;
  --azul: rgba(5, 5, 242, 0.7);
  --blanco-original: #fbfcfc;
  --email: #2420df;
  --marron-original: #d5a16a;
  --marron-original-desgastado: rgba(213, 161, 106, 0.5);
  --naranja-transparante: rgba(255, 137, 20, 0.4);
  --naranja-transparante-borde: rgba(255, 137, 20, 0.5);
  --negro-intenso: #0a0a0a;
  --whatsapp: #29df20;
  --naranja: #f78b2d;
  --tamanoFooter: 150px;
  --navBar: 70px;
  --paddingArribaFooter: 90px;
  --tamanoImageError404: 300px;
  --fucsia: #08fdd8;
  --celeste: #08fdd8;
  --celeste2: #08fdd8;
  --fucsia2: #fd2155;
  --tw-text-opacity: 1;
  --tw-bg-opacity: 1;
}

/**  -------------------- COMPONENTE NAVBAR -------------------- */

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--navBar);
  padding: 0 2rem;
  background-color: black;
  color: var(--textColor);
}

nav a {
  margin: 0 0.5rem;
  color: var(--textColor);
  text-decoration: none;
}

nav a:hover {
  color: var(--celeste);
  transition: 0.5s;
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: var(--textColor);
  font-size: 1.8rem;
}

header .nav-btn {
  visibility: visible;
  opacity: 100;
}

header .responsive_nav {
  transform: none;
  z-index: 4;
}

nav .nav-close-btn {
  position: absolute;
  top: 2rem;
  right: 2rem;
}

nav a {
  font-size: 1.5rem;
}

header nav {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: black;
  transition: 1s;
  transform: translateY(-100vh);
  z-index: 0;
}

.headerTitle {
  font-size: 1.5em;
}

.header-logo-Container {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
}

.logo{
  position: absolute;
  width: 50px;
  height: 50px;
  bottom: -27px;
  left: -10px;
}

.logoNicolas{
  position: absolute;
  top: -15px;
  left: 48px;
  width: 140px;
}

.header-logo-Container div a {
  display: flex;
  flex-direction: column;
  font-size: 15px;
  line-height: 16px;
  position: absolute;
  left: 75px;
  top: 22px;
  text-decoration: none;
}

.headerTitle__onePart {
  color: #08fdd8;
  font-size: 55px;
  font-weight: bold;
}

.header-logo-Container a {
  text-decoration: none;
}

.headerTitle__secondPart {
  color: white;
}

.headerBtnOpen:hover {
  color: var(--lox);
  transition: 0.5s;
}

.headerBtnClose:hover {
  color: rgb(231, 49, 49);
  transition: 0.5s;
}

/**  -------------------- COMPONENTE FOOTER -------------------- */

.contenedorPrincipal {
  min-height: calc(99vh - var(--tamanoFooter) - var(--navBar));
  z-index: 2;
}

.newFooter {
  position: absolute;
  left: 0;
  width: 100%;
  height: var(--tamanoFooter); /* Es el calculo del Footer, deberia ser una variable. */
  background-color: black;
}

.newFooter__container {
  color: white;
  display: grid;
  grid-template-columns: 1fr;
  gap: 50px;
  width: 90%;
  margin: auto;
  align-items: stretch;
  max-height: var(--tamanoFooter);
  /* overflow: hidden; Quizas BORRAR */
  padding: 20px 0 20px;
  max-width: 1000px;
}


.newFooter__box {
  display: grid;
  display: none;
  grid-template-columns: 1.2fr 1fr 1fr;
  column-gap: 15px;
  justify-items: center;
}

.newFooter__container h4 {
  font-size: 14px;
  margin-bottom: 8px;
  letter-spacing: 1px;
  text-align: center;
}

.newFooter__container p {
  font-size: 14px;
  padding: 7px 0;
  color: var(--gray-pastel);
  text-align: center;
}

.newFooter__itemsBox {
  padding-left: 7px;
  border-left: solid 1px var(--celeste);
}


.newFooter__titleSpan {
  color: var(--celeste);
}

.newFooter__box h4 {
  text-align: left;
}

.newFooter__box p {
  text-align: left;
}

/**  -------------------- ORDENAR -------------------- */

.containerCenterWeb { /* Margen exterior rojo */
  margin: 10px;
}

.NicolasTwo { /* Margen exterior azul */
  margin: 10px;
  padding: 15px; 
}

.homeTitle{
  color: rgb(148 163 184/var(--tw-text-opacity))
}

/**  -------------------- Componente HOME -------------------- */

.homeGeneral{
  /*background-color: rgb(15 23 42/var(--tw-bg-opacity));
  background-image: url(../images/Fondos/hero.jpg);*/
  background-image: url(../images/Fondos/patternn.svg);
  margin: 10px 10px 0px 10px;
}

.homeGeneral .textInfo{
  margin: 30px 0;
}

.homeContainer { /* Margen exterior azul */
  padding: 15px; 
  max-width: 1600px;
  margin: auto;
}

.textoWeb{
  color: var(--fucsia);
  font-size: 28px;
  padding: 10px 0 0 ;
}

.textoCambiante{
  color: var(--fucsia);
  font-size: 18px;
  padding: 0 0 10px;
}

.homeParrafos{
  margin: 10px 0;
}

.masInformacionTitle{
  margin: 15px 0;
}

.homeCards{
  margin-bottom: 20px;
}



.homeCards:hover{
  /* box-shadow: 1px 0px 5px 1px rgba(253,33,85,0.49);
  -webkit-box-shadow: 1px 0px 5px 1px rgba(253,33,85,0.49);
  -moz-box-shadow: 1px 0px 5px 1px rgba(253,33,85,0.49); */

  box-shadow: 1px 0px 5px 1px rgba(8,253,216,0.49);
  -webkit-box-shadow: 1px 0px 5px 1px rgba(8,253,216,0.49);
  -moz-box-shadow: 1px 0px 5px 1px rgba(8,253,216,0.49);
  border-radius: .5rem;

  transition: all .3s;
}

.card-content{
  display: flex;
  flex-direction: column;

  background-color: rgb(30 41 59/var(--tw-bg-opacity));
  /* background-color: rgb(53, 53, 53); VERSION ANTERIOR */
  padding: 1.5rem;
  justify-content: space-between;
  border-radius: .5rem;
/*
  background-color: rgb(53, 53, 53);
  padding: 25px;
  justify-content: space-between;
  border-radius: 4px;
*/
}

.esconder{
  display: none;
}

.card-content img {
  margin: 5px 5px 0 0;
  border-radius: 50%;
  width: 40px;
}

.card-content:hover{
  background-color: rgb(40 52 73/var(--tw-bg-opacity));
}



.card-content h3{
  border-bottom: solid 1px var(--celeste);
  padding: 0px 0 7px;
  text-align: center;
  font-size: 18px;
  /* height: 62px;
  display: flex;
  justify-content: center;
  align-items: center; */
}

.card-content p{
  padding: 10px 0 3px;
}

.card-link-info{
  margin: 12px 0 0 0;
  padding: 8px;
  background-color: var(--fucsia);
  text-align: center;
  display: block;
  color: var(--black);
  cursor: pointer;
  width: 100%;
  border: none;
  border-radius: 3px;
  font-size: 14px;
  border-radius: .375rem;
}

.homeSections{
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 20px;
}

.proyectSection{
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 20px;
}

.card-link-info a {
  text-decoration: none;
}

.card-content a {
  text-decoration: none;
}

.card-content a{
  position: relative;
  overflow: hidden;
}

.card-content span{
  position: relative;
  z-index: 2;
}

.botonHome::after{
  content: "";
  width: 100%;
  position: absolute;
  z-index: 1;
  top: 12px;
  left: calc(-100% - 80px);
  border-bottom: 37px solid #16ebab ;
  border-right: 37px solid transparent;
  transition: .4s ease-in-out all;
  overflow: hidden;
}

.botonHome:hover::after{
  left: 0;
}

.card-link-info:hover {
  color: black;
}

/*

Opciones de Colores para el boton de mas Arriba:

#FF5733 (rojo intenso)
#FFC0CB (rosa pastel)
#800080 (morado oscuro)
#FFFFFF (blanco)
#000000 (negro)
#808080 (gris medio)
#90EE90 (verde claro)
#ADD8E6 (azul claro)
#1E90FF (azul cielo)
*/

/* Redes Sociales */

.redesSocialesContainer{
  position: relative;
  height: 60px;
  margin: 30px auto auto;
}

.redesSocialesContainer ul {
  margin: 0;
  padding: 0;
  display: flex;
  position: absolute;
  top: 50%;
  left: 75px;
  transform: translate(-50%, -50%);
}

.redesSocialesContainer ul li {
  list-style: none;
  margin: 0 10px;
}

.redesSocialesContainer ul li a {
  position: relative; 
  display: block;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 63px;
  background: rgb(30 41 59/var(--tw-bg-opacity));
  /* background: #333; *Version Anterior */
  border-radius: 50%;
  font-size: 30px;
  color: #666;
  transition: .5s;
}

.redesSocialesContainer ul li a::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: var(--celeste);
  transition: .5s;
  transform: scale(.9);
  z-index: -1;
}

.redesSocialesContainer ul li a:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px var(--celeste);
}

.redesSocialesContainer ul li a:hover {
  color: var(--celeste);
  box-shadow: 0 0 5px var(--celeste);
  text-shadow: 0 0 5px var(--celeste);
}

.iconHome{
  position: relative;
  top: 5%;
  left: 1%;
}

/**  -------------------- Componente PROYECT CARDS -------------------- */

.card-link-info-proyects {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-top: 15px;
}

.card-link-info-proyects button{
  width: 100%;
  height: 40px;
  background-color: var(--celeste);
  border: none;
  cursor: pointer;
  font-size: 14px;
  position: relative;
  z-index: 2;

  border-radius: .375rem;

/*
  border-radius: 4px;
>>>>>>> 13e3ee3ba6228f6780accc1e3092b5459567fc97*/

}

.botonProyect::after{
  content: "";
  width: 100%;
  position: absolute;
  z-index: 1;
  top: 0px;
  left: calc(-100% - 80px);
  border-bottom: 40px solid #16ebab ;
  border-right: 40px solid transparent;
  transition: .4s ease-in-out all;
  overflow: hidden;
}

.botonProyect:hover::after{
  left: 0;
}

/**  -------------------- COMPONENTE SKILLS VIEJO -------------------- */

.skillsContainer {
  display: inline-flex;
  padding: 10px;
  width: 100%;
}

.skillsContainer:hover {
  box-shadow: 1px 0px 5px 1px rgba(8,253,216,0.49);
  -webkit-box-shadow: 1px 0px 5px 1px rgba(8,253,216,0.49);
  -moz-box-shadow: 1px 0px 5px 1px rgba(8,253,216,0.49);
  transition: all .5s;
  background-color: rgb(53, 53, 53);
}

.skillsContainer p, .skillsContainer h3{
  font-size: 16px;
}


.skillsContainer h3{
  padding-right: 5px;
  color: var(--celeste);
}

/**  -------------------- COMPONENTE SKILLS NUEVO -------------------- */

.skillsContainerNew{
  margin: 10px;
  padding: 20px;
  background-color: rgb(30 41 59/var(--tw-bg-opacity));
  /* background-color: rgb(53, 53, 53);*/
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  border-radius: .5rem;
}

.skillLayaoutGrid{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.skillsContainerNew:hover {
  box-shadow: 1px 0px 5px 1px rgba(8,253,216,0.80);
  -webkit-box-shadow: 1px 0px 5px 1px rgba(8,253,216,0.80);
  -moz-box-shadow: 1px 0px 5px 1px rgba(8,253,216,0.80);
  transition: all .35s;
  background-color: rgb(40, 52, 73);
  /* background-color: rgb(53, 53, 53); */
  color: var(--celeste);
}

.image{
  width: 100px;
  height: 100px;
  margin: auto;
}

.image img{
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.skillTituloContainer h3{
  border-top: solid 1px var(--celeste);
  text-align: center;
  margin-top: 15px;
  padding: 5px 0;
  width: 240px;
}

.skillsContainerNew:hover img{
  transition: all 0.65s;
	transform: rotateY(360deg);
}

/**  -------------------- COMPONENTE CONTACTO -------------------- */

.contactFormContainer { /* Margen exterior azul */
  margin: 0 auto 40px;
  padding: 2.5rem; 
  max-width: 800px;
  border-radius: 1rem;
  background-color: rgb(30 41 59/var(--tw-bg-opacity));
  box-shadow: inset 0 1px 0 0 #ffffff1a;
}


.contactFormContainer label {
  display: block;
  width: 100%;
  margin: 5px 0px 10px;
}

.contactFormContainer input, .contactFormContainer textarea {
  display: block;
  width: 100%;
  padding: 8px;
  outline: none;
  border: none;
  border-radius: 2px;
  background-color: rgb(51 65 85/var(--tw-bg-opacity));
  color: white;
}

.contactFormContainer textarea{
  height: 80px;
}

.contactFormContainer input::placeholder , .contactFormContainer textarea::placeholder {
  color: white;
  opacity: .45;
  font-size: 12px;
}

.contactFormContainer input:focus, .contactFormContainer textarea:focus {
  /* box-shadow: 1px 0px 5px 1px rgba(8,253,216,0.50);
  -webkit-box-shadow: 1px 0px 5px 1px rgba(8,253,216,0.50);
  -moz-box-shadow: 1px 0px 5px 1px rgba(8,253,216,0.50); */
  border-bottom:solid 2px rgba(8,253,216,0.50);
  /* transition: all .2s; */
}

.contactFormContainer input:focus, .contactFormContainer textarea:focus {
  background-color: transparent;
}

.contactFormContainer input[type="submit"]{
  background-color: var(--celeste);
  border: none;
  margin-top: 25px;
  cursor: pointer;
  font-size: 15px;
  padding: 10px;
  border-radius: .375rem;
  color: black;
}

.contactText{
  text-align: center;
}

.contactFormContainer div{
  margin: 20px 0;
}

.contactFormContainer span{
  background-color: transparent;
  color: white;
  padding: 0 10px 10px 10px;
  display: block;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  border-bottom: solid 2px var(--celeste);
}

.enviadoConExito{
  width: 261px;
  margin: 20px auto;
  text-align: center;
  background-color: var(--fucsia2); /*#4ba344*/
  padding: 10px;
  color: white;
  font-weight: 600;
  font-size: 17px;
}




/**  -------------------- ANIMACION DE ESCRITURA -------------------- */

.wrapper{
  display: flex;
  height: 50px;
}

.wrapper .texto-dinamico{
  height: 50px;
  line-height: 50px;
  overflow: hidden;
}

.texto-dinamico li{
  list-style: none;
  color: var(--celeste);
  font-size: 20px;
  font-weight: 500;
  position: relative;
  top: 0;
  animation: pasaje 15s steps(3) infinite;
}

@keyframes pasaje {
  100%{
    top: -150px;
  }
}

.texto-dinamico li span{
  position: relative;
  margin: 5px 0;
  line-height: 50px;
}

.texto-dinamico li span::after{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgb(11 17 33/var(--tw-bg-opacity));
  /* background: rgb(15 23 42/var(--tw-bg-opacity)); background: #1d1d1d; Version Vieja */
  border-left: 2px solid white;
  animation: tipeo 5s steps(31) infinite;
}

@keyframes tipeo {
  40%, 60%{
    left: calc(100% + 5px);
  }
  100%{
    left: 0;
  }
}

/* TODO ---------------------------------------- SEPARADOR DE QUERYS ---------------------------------------- */

/* ! -------------------- QUERYY SELECTOR 480PX --------------------  */

@media only screen and (min-width: 480px) {

  /**  -------------------- COMPONENTE FOOTER -------------------- */

  .newFooter__itemsBox {
    border-left: solid 1px var(--celeste);
  }

  .newFooter__container h4 {
    font-size: 16px;
  }

  .newFooter__container p {
    font-size: 14px;
  }
}

/* ! -------------------- QUERYY SELECTOR 520PX --------------------  */

@media only screen and (min-width: 520px) {

  /**  -------------------- COMPONENTE HOME -------------------- */

  .homeSections{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 18px;
  }

  .proyectSection{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 18px;
  }

  .homeCards{
    margin: 0;
    display: flex;
    align-items: stretch;
  }

}

/* ! -------------------- QUERYY SELECTOR 768PX --------------------  */

@media only screen and (min-width: 768px) {

  /**  -------------------- COMPONENTE HOME -------------------- */


  /** -------------------- COMPONENTE NAVBAR -------------------- */

  header .nav-btn {
    visibility: hidden;
    opacity: 0;
    display: none;
  }

  nav a {
    font-size: 1rem;
    padding: 8px;
    font-weight: bold;
  }

  nav a:hover {
    transition: 1s;
    /* border-radius: 5px; */
    color: black;
    background-color: var(--celeste);
  }

  .active {
    color: black;
    background-color: var(--celeste);
  }

  header nav {
    position: relative;
    top: initial;
    left: initial;
    height: auto;
    width: auto;
    flex-direction: initial;
    align-items: center;
    transition: none;
    transform: none;
  }

  /* Animaciones a la Navbar */

  .Nicolas a {
    position: relative;
    display: block;
    text-transform: uppercase;
    margin: 4px;
    padding: 10px 10px;
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
    transition: 0.5s;
    z-index: 1;
    border-radius: .375rem;
  }

  .Nicolas a:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-top: 2px solid var(--celeste);
    border-bottom: 2px solid var(--celeste);
    transform: scaleY(2);
    opacity: 0;
    transition: 0.3s;
    border-radius: .375rem;
  }

  .Nicolas a:after {
    content: "";
    position: absolute;
    top: 2px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--celeste);
    transform: scale(0);
    opacity: 0;
    transition: 0.3s;
    z-index: -1;
  }
  
  .Nicolas a:hover {
    color: black;
  }
  
  .Nicolas a:hover:before {
    transform: scaleY(1);
    opacity: 1;
  }

  .Nicolas a:hover:after {
    transform: scaleY(1);
    opacity: 1;
  }

  /**  -------------------- COMPONENTE FOOTER -------------------- */

  .newFooter__box {
    display: grid;
  }

  .newFooter__container {
    grid-template-columns: 1fr 1fr;
  }

  .newFooter__desktop h4 {
    text-align: left;
  }
  
  .newFooter__desktop p {
    text-align: left;
  }

}


/* ! -------------------- QUERYY SELECTOR 992PX --------------------  */

@media only screen and (min-width: 992px) {

  /**  -------------------- COMPONENTE HOME -------------------- */

  .homeSections{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 18px;
  }

  .proyectSection{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 18px;
  }

}

/* ! -------------------- QUERYY SELECTOR 1200PX --------------------  */

@media only screen and (min-width: 1200px) {

  .proyectSection{
    grid-template-columns: repeat(3, 1fr);
  }

}


@media only screen and (min-width: 1500px) {

  .proyectSection{
    grid-template-columns: repeat(4, 1fr);
  }

}

